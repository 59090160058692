import axios from 'axios'
import qs from 'qs'

axios.defaults.timeout = 180000 // 3m

export default class HTTP {
    constructor (baseUrl) {
        this.http = axios.create({
            baseURL: baseUrl || '',
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat',
                    indices: false,
                    allowDots: false,
                    skipNulls: true,
                    format: 'RFC1738'
                })
            },
            validateStatus: status => status >= 200 && status < 400
        })

        return this
    }

    // publics

    get (resource, params) {
        return new Promise((resolve, reject) => {
            this.http.get(resource, { params: params })
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    post (resource, data, headers) {
        if (data) data.END_PAYLOAD = true

        return new Promise((resolve, reject) => {
            this.http.post(resource, data, { headers: headers })
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    put (resource, data) {
        if (data) data.END_PAYLOAD = true

        return new Promise((resolve, reject) => {
            this.http.put(resource, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    patch (resource, data) {
        if (data) data.END_PAYLOAD = true

        return new Promise((resolve, reject) => {
            this.http.patch(resource, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }

    delete (resource, data) {
        return new Promise((resolve, reject) => {
            this.http.delete(resource, data)
                .then(res => resolve(res.data))
                .catch(err => reject(err.response.data))
        })
    }
}
