import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Policy from '../views/Policy.vue'

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index
    },
    {
        path: '/policy',
        name: 'policy',
        component: Policy
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
