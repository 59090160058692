<template>
    <div class="index">
        <div class="index--wall">
            <h1
            ref="title_wall"
            v-html="$store.state.me.motto"
            data-split
            class="ma-0 pa-0"></h1>
        </div>

        <carousel
        ref="carousel"
        autoplay
        snappable
        draggable
        :item-per-view="itemPerView"
        :items="posts"
        data-cursor="drag"
        class="index--carousel">
            <template #item="{ item }">
                <!-- :style="{ transform: `scale(${(delta * 0.0025) + 1})` }" -->
                <div class="index--carousel--item">
                    <a
                    :href="item.permalink"
                    target="_blank">
                        <figure-c
                        :src="item.media_type === 'video' ? item.thumbnail_url : item.media_url"
                        :alt="item.caption"
                        ratio="1">
                            <div class="caption">
                                <small>{{ item.caption }}</small>
                            </div>
                        </figure-c>
                    </a>
                </div>
            </template>

            <template #controls="{ progress }">
                <div
                ref="carousel_controls"
                class="index--carousel--controls">
                    <small>scroll or drag to discover</small>
                    <div class="index--carousel--controls--progress">
                        <div :style="{ transform: `scaleX(${progress.sign})` }"></div>
                    </div>
                </div>
            </template>
        </carousel>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Carousel from '@/components/Carousel.vue'

const isMobile = window.innerWidth < 1024

export default {
    name: 'index',
    components: {
        carousel: Carousel
    },
    computed: {
        ...mapState({
            posts: state => state.ig_items
        })
    },
    data () {
        return {
            itemPerView: isMobile ? 1 : 2
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq';

.index {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;

    &--wall {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: calc(var(--size-ui) * 4) var(--size-ui);
        z-index: 1;
        pointer-events: none;
        word-wrap: break-word;

        > h1 {
            font-size: 12vw;
            text-transform: uppercase;
            word-wrap: break-word;

            @include mq(ds) {
                font-size: 8vw;
            }
        }
    }

    &--carousel {
        z-index: 2;

        &--item {
            position: relative;
            width: 100%;
            max-width: 80vw;
            height: 100%;
            margin: 0 auto;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            user-select: none;
            pointer-events: none;
            // border: 1px solid red;

            @include mq(ml) {
                max-width: 50vh;
            }

            @include mq(ds) {
                max-width: 45vh;
            }

            > a {
                display: block;
                width: 100%;
                visibility: hidden;
                pointer-events: auto;

                > .figure {
                    overflow: hidden;
                    visibility: visible;

                    @media (hover: hover) {
                        &:hover {
                            .caption {
                                transform: translateY(0);
                            }
                        }
                    }

                    .caption {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        background-color: var(--color-background);
                        color: var(--color-typo);
                        padding: 1rem 3rem 3rem 1rem;
                        transform: translateY(100%);
                        transition: transform 0.3s ease-out;
                    }
                }
            }
        }

        &--controls {
            position: absolute;
            bottom: 0;
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            padding: calc(var(--size-ui) * 5) 0;
            z-index: 1;
            pointer-events: none;

            @include mq(ds) {
                padding: calc(var(--size-ui) * 3) 0;
            }

            > small {
                margin: 0;
                padding: 0;
                font-size: 12px;
                margin-bottom: 0.5rem;
            }

            &--progress {
                position: relative;
                width: 100%;
                max-width: 160px;
                height: 2px;
                margin: 0 auto;
                background-color: var(--color-mute);
                overflow: hidden;
                border-radius: 4px;

                > div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 98%;
                    transform-origin: left top;
                    transform: scaleX(0);
                    // transition: transform 0.4s ease-out;
                    background-color: var(--color-primary);
                }
            }
        }
    }
}
</style>
