<template>
    <div
    :class="[
    'carousel',
    'carousel-' + dir,
    {
        'carousel-draggable': draggable,
        'dragging': dragging,
        'scrolling': scrolling,
        'debug': debug
    }]">
        <!-- DEBUG -->
        <div
        v-if="debug"
        class="debugger">
            <p>animating {{ animating }} (wait lerp)</p>
            <p>draggin {{ dragging }}</p>
            <p>scrolling {{ scrolling }}</p>
            <p>lastEvt {{ lastEvt }}</p>
            <p>dir {{ direction }}</p>
            <p>Index {{ index }}</p>
            <p>Item left: {{ cacheItems[index].left }}</p>
            <hr style="margin: 0.5rem 0" />

            <p>scroll target {{ scrollTarget }}</p>
            <p>normalizedScrollTarget {{ normalizedScrollTarget }}</p>
            <p>deltaTarget {{ deltaTarget }}</p>
            <br>
            <p>scroll old {{ oldScrollValue }}</p>
            <p>scroll val {{ scrollValue }}</p>
            <p>normalizedScroll {{ normalizedScroll }}</p>
            <p>delta {{ delta }}</p>
            <p>progress {{ progress.sign }}</p>
            <p>Last closet {{ lastCloset }}</p>
            <hr style="margin: 0.5rem 0" />

            <p>item progress [0] {{ cacheItems[0].progress }}</p>
            <hr style="margin: 0.5rem 0" />

            <p>windowW {{ windowW }}</p>
            <p>windowH {{ windowH }}</p>
            <p>snapTrigger {{ windowCenter }}</p>
            <p>content totalWidth {{ totalWidth }}</p>
        </div>

        <div
        v-if="debug"
        class="debugger-snap-trigger"
        :style="{ left: `${windowCenter}px` }">
        </div>

        <!-- HIDDEN IMGS -->
        <!-- <div class="hidden">
            <img
            v-for="(item, i) in cacheItems"
            :key="i"
            :src="item.doc[itemSrc]"
            class="hidden">
        </div> -->

        <slot
        name="wall"
        :delta="delta" />

        <!-- RAIL -->
        <div
        ref="rail"
        :class="[
            'carousel--rail-cnt',
            { 'no-evts': dragging || scrolling }
        ]"
        :style="{
            width: `${windowW * cacheItems.length}px`,
            height: `${windowH}px`
        }">
            <div
            v-for="item in cacheItems"
            :key="item.doc.id"
            ref="item"
            class="carousel--rail-cnt--item-cnt"
            :style="{
                top: `${item.top}px`,
                left: `${item.left}px`,
                width: `${item.width}px`,
                height: `${item.height}px`,
                transform: `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${scrollValue}, 0, 0, 1)`
            }">
                <!-- :style="{
                    transform: `translate3d(0, ${Math.abs(item.progress * 1)}px, 0))`
                }" -->
                <div class="carousel--rail-cnt--item-cnt--item-inner-cnt">
                    <slot
                    name="item"
                    :scrollValue="scrollValue"
                    :itemPosition="item.left"
                    :delta="delta"
                    :index="index"
                    :item="item.doc" />
                </div>
            </div>
        </div>

        <slot
        name="controls"
        :animating="animating"
        :progress="progress"
        :prev="prev"
        :next="next" />
    </div>
</template>

<script>
import VirtualScroll from 'virtual-scroll'
import Hammer from 'hammerjs'
import { gsap } from 'gsap'
import Stats from 'stats.js'
// import NormalizeWheel from 'normalize-wheel'

import {
  getBrowser,
  range,
  lerp,
  toDec,
//   clamp,
  getClosets
} from '@/assets/libs/utils'

export default {
  name: 'slider',
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: 0
    },
    items: {
      type: Array,
      required: false,
      default: () => [1, 2, 3, 4, 5, 6]
    },
    'item-src': {
        type: String,
        required: false,
        default: 'url'
    },
    ease: {
      type: Number,
      required: false,
      default: 0.1
    },
    'ease-snap': {
      type: Number,
      required: false,
      default: 0.08
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    debug: {
      type: Boolean,
      required: false,
      default: false
    },
    'debug-item': {
      type: Boolean,
      required: false,
      default: false
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: true
    },
    snappable: {
      type: Boolean,
      required: false,
      default: false
    },
    dir: {
      type: String,
      required: false,
      default: 'horizontal'
    },
    'item-per-view': {
      type: [String, Number],
      required: false,
      default: 1
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    infinite: {
        type: Boolean,
        required: false,
        default: false
    }
  },
  computed: {
    progress () {
        const progress = (100 / this.totalWidth) * this.normalizedScroll
        const sign = 1 / (this.totalWidth / this.normalizedScroll)

        return {
            percentage: Math.abs(Math.round(progress)),
            sign: Math.abs(sign)
        }
    }
  },
  data () {
    return {
      cacheItems: [],
      windowW: 0,
      windowH: 0,
      windowCenter: 0,
      totalWidth: 0,

      scrolling: false,
      dragging: false,
      animating: false,
      lastEvt: null,
      stats: null,

      lerp: this.ease,

      direction: null,
      index: 0,

      scrollTarget: this.value,
      scrollValue: 0,
      oldScrollValue: 0,
      normalizedScroll: 0,
      normalizedScrollTarget: 0,
      deltaTarget: 0,
      delta: 0,
      lastCloset: 0,
      scrollTimer: null
    }
  },
  created () {
    if (this.debug) {
      this.stats = new Stats()
      this.stats.showPanel(0)
      document.body.appendChild(this.stats.dom)
    }

    this.items.forEach((item, i) => {
      this.cacheItems.push({
        scrollValue: 0,
        top: 0,
        left: 0,
        width: 0,
        progress: 0,
        rotate: 0,
        doc: item
      })
    })
  },
  mounted () {
    this.$nextTick(this.start)
  },
  watch: {
      index () {
        this.$emit('input', this.index)
      }
  },
  methods: {
    hasScopeSlot (key) {
      return key in this.$slots
    },

    // INITIALS ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    start () {
      this.resize()

      this.$nextTick(() => {
        if (this.scrollable) {
          this.vs = new VirtualScroll({
            el: this.$el,
            // keyStep: -300,
            mouseMultiplier: getBrowser().name !== 'firefox' ? 0.1 : 0.5, // Moltiplicatore generale per tutte le rotellina del mouse (incluso Firefox)
            touchMultiplier: 2, // Ripeti l'azione di tocco di questo modificatore per rendere lo scorrimento più veloce del movimento delle dita
            firefoxMultiplier: 15, // Firefox su Windows ha bisogno di una spinta, poiché lo scorrimento è molto lento.
            preventTouch: false, // Se true, chiama automaticamente e.preventDefault su touchMove.
            passive: true, // se utilizzato, utilizzerà la dichiarazione di eventi passivi per gli ascoltatori wheel e touch.
            useTouch: true,
            useKeyboard: false
          })
        }

        if (this.draggable) {
          this.hammer = new Hammer(this.$el)
          this.hammer.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL, threshold: 1, pointers: 1 })
          this.hammer.get('press').set({ pointer: 1, threshold: 1000, time: 1 })
        }

        if (this.autoplay) this.play()
      })
    },

    play () {
        this.resize()
      window.addEventListener('resize', this.resize)

      if (this.scrollable) {
        // this.$el.addEventListener('mousewheel', this.handlerScroll)
        // this.$el.addEventListener('wheel', this.handlerScroll)
          this.vs.on(this.handlerScroll, this)
        }

      if (this.draggable) {
        this.hammer.on('panleft', this.handlerPan)
        this.hammer.on('panright', this.handlerPan)
        this.hammer.on('panend', this.handlerPressOff)

        this.hammer.on('press', this.handlerPressOn)
        this.hammer.on('pressup', this.handlerPressOff)
      }

      gsap.ticker.add(this.render)
    //   this.normalizedScrollTarget = clamp(this.scrollTarget, -this.totalWidth, this.totalWidth)
    },

    stop () {
      if (this.scrollable) {
        //   this.$el.removeEventListener('mousewheel', this.handlerScroll)
        // this.$el.removeEventListener('wheel', this.handlerScroll)
          this.vs.off(this.handlerMove)
        }

      if (this.draggable) {
        this.hammer.off('panleft')
        this.hammer.off('panright')
        this.hammer.off('press')
        this.hammer.off('panend')
        this.hammer.off('pressup')
      }

      gsap.ticker.remove(this.render)

      window.removeEventListener('resize', this.resize)
    },

    // RESIZING / POS ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    resize () {
      if (this.debug) console.log('resize')

      this.windowW = window.innerWidth
      this.windowH = window.innerHeight

      /* snap (prev/next) trigger */
      this.windowCenter = this.windowW / 2 // toDec((this.windowW / 2) / parseInt(this.itemPerView))

      this.setItemsPos()

      this.$nextTick(() => {
        this.chekItems()
        if (this.snappable) this.goTo(this.index)
      })
    },
    setItemsPos () {
      if (this.debug) console.log('setItemsPos')

      let left = 0
      this.totalWidth = 0

      this.cacheItems.forEach((item, i) => {
        if (this.debug) console.log(this.$refs.item[i])
        item.el = this.$refs.item[i]

        item.width = toDec((this.windowW / parseInt(this.itemPerView)))
        item.height = this.windowH

        item.top = 0
        item.left = toDec(left)
        item.initialLeft = item.left

        left += item.width
        this.totalWidth += item.width
      })

      this.totalWidth = toDec(this.totalWidth)
    },

    // EVENTS ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    handlerScroll (e) {
      if (this.disabled) return
      if (this.debug) console.log('scrolling')

      this.scrolling = true
      this.lastEvt = 'scroll'
      this.direction = this.oldScrollValue > this.scrollValue ? 'left' : 'right'

    //   const normalized = NormalizeWheel(e)
    //   this.deltaTarget = -(normalized.pixelY * 1)

      this.deltaTarget = toDec(e.deltaY || e.deltaX)
      this.scrollTarget += this.deltaTarget

      this.handlerMove()

      clearTimeout(this.scrollTimer)
      this.scrollTimer = setTimeout(this.handlerMoveStop, 400)

    //   e.preventDefault()
    //   e.stopPropagation()
    },
    handlerPan (e) {
      if (this.disabled) return

      if (this.debug) console.log('panning', e.isFinal)

      this.dragging = true
      this.lastEvt = 'pan'
      this.direction = this.oldScrollValue > this.scrollValue ? 'left' : 'right'
      const multi = getBrowser().name === 'chrome' ? 30 : 50

      if (this.debug) console.log(e)
      this.deltaTarget = toDec(e.velocityX * multi)
      this.scrollTarget += this.deltaTarget

      this.handlerMove()
    },
    handlerPressOn (e) {
      if (this.disabled) return

      if (this.debug) console.log(e.type)

      this.dragging = true
      this.$emit('press', this.cacheItems)
    },
    handlerPressOff (e) {
      if (this.disabled) return

      if (this.debug && e) console.log(e.type)

      this.$emit('pressup', this.cacheItems)
      this.handlerMoveStop()
    },
    //
    handlerMove (e) {
      if (this.disabled) return

      // normalized scroll
      if (this.debug) console.log('handlerMove')

      this.lerp = this.ease

      this.scrollTarget = toDec(this.scrollTarget)

    //   this.normalizedScrollTarget = clamp(this.scrollTarget, -this.totalWidth, this.totalWidth)
      this.normalizedScrollTarget += toDec(this.deltaTarget)
      this.normalizedScrollTarget = toDec(this.normalizedScrollTarget)
      if (Math.abs(this.normalizedScrollTarget) >= this.totalWidth) this.normalizedScrollTarget = 0

      const closets = this.closest()
      this.index = closets.index

      this.$emit('move')
      this.$emit('change', this.index)
    },
    handlerMoveStop () {
      if (this.disabled) return

      if (this.debug) console.log('handlerMoveStop')

      this.deltaTarget = 0
      this.dragging = false
      this.scrolling = false

      this.snap()
    },
    next () {
      if (this.index >= this.items.length - 1) this.goTo(0)
      else this.goTo(this.index += 1)
    },
    prev () {
      if (this.index <= 0) this.goTo(this.items.length - 1)
      else this.goTo(this.index -= 1)
    },
    goTo (i) {
      if (this.disabled) return

      if (this.debug) console.log('go to', i)
      this.snap(i)
    },

    // UTILS ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    chekItems () {
      if (this.debug) console.log('chekItems')

      this.cacheItems.forEach((item) => {
        const snapTriggerReverse = toDec((this.windowW / 2) / parseInt(this.itemPerView)) // -this.windowCenter
        const condition = this.direction === 'left' ? toDec(item.left + this.scrollValue + item.width) < -snapTriggerReverse : toDec(item.left + this.scrollValue) > this.windowW
        const calc = this.direction === 'left' ? toDec(item.left + this.totalWidth) : toDec(item.left - this.totalWidth)
        item.left = condition ? toDec(calc) : toDec(item.left)

        const bounds = item.el.getBoundingClientRect()

        item.progress = toDec(range(
          bounds.left - this.windowW,
          bounds.right,
          1, -1, 0
        ))
      })
    },
    snap (i) {
        if (!this.snappable && !i) return

        if (this.debug) console.log('snap')

        this.lerp = this.easeSnap || this.ease

        const { index, compensation } = this.closest(i)

        this.index = index
        this.lastCloset = compensation

        this.scrollTarget += compensation
        this.scrollTarget = toDec(this.scrollTarget)
        this.normalizedScrollTarget = this.direction === 'left' ? -this.cacheItems[this.index].initialLeft : this.cacheItems[this.index].initialLeft
    },
    closest (i) {
      if (this.debug) console.log('closest')

      const numbers = []

      this.cacheItems.forEach((item, i) => {
        const bounds = item.el.getBoundingClientRect()

        const diff = toDec((this.scrollTarget - 3) - this.scrollValue)
        const center = toDec((bounds.x + diff) + (bounds.width / 2))
        const fromCenter = toDec(this.windowCenter - center)

        if (this.debug) console.log('index:', i, 'r:', bounds.right, 'l:', bounds.left, 'x:', bounds.x, 'w:', bounds.width, 'diff:', diff, 'center:', center)

        numbers.push(fromCenter)
      })

      let index = getClosets(0, numbers, (comparedItem, item) => Math.abs(comparedItem - item))
      if (i !== undefined) index = i
      const compensation = toDec(numbers[index])

      if (this.debug) console.log(numbers, compensation, index)

      return {
        numbers,
        compensation,
        index
      }
    },

    // RENDER ---------------------------------------------------------------------------------------------------------

    render () {
      if (this.disabled) return

      if (this.debug) this.stats.begin()

      this.oldScrollValue = toDec(this.scrollValue)
      this.scrollValue += toDec((this.scrollTarget - this.scrollValue) * this.lerp)
      this.scrollValue = toDec(this.scrollValue)

      this.normalizedScroll = toDec(lerp(this.normalizedScroll, this.normalizedScrollTarget, this.lerp))
      this.delta = toDec(lerp(this.delta, this.deltaTarget, this.lerp))

      this.chekItems()

      if (this.oldScrollValue === this.scrollValue) {
        this.animating = false
        return
      }

      this.animating = true

      if (this.debug) this.stats.end()
    }
  },
  beforeUnmount () {
    this.stop()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.debugger {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 440px;
    background-color: white;
    padding: 1rem 4rem;
    z-index: 20;
    font-size: 12px;
    border: none !important;

    p {
        color: black !important;
        margin: 0 !important;
        padding: 0 !important;
        border: none !important;
    }
}

.debugger-item {
    position: scale;
    bottom: 0;
    left: 0;
    top: 0;
    margin: auto;
    width: 440px;
    background-color: white;
    padding: 1rem 4rem;
    z-index: 20;
    font-size: 12px;
}

.debugger-snap-trigger {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background-color: red;
    z-index: 1;
}

.carousel {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    user-select: none;
    overflow: hidden;

    &.debug {
        // border: 1px solid red;

        > div {
            // rail
            border: 3px solid orange;

            > div {
                // item rail
                border: 2px solid green;
            }
        }
    }

    &.carousel-draggable {
        cursor: grab;
    }

    &.dragging {
        cursor: grabbing;
    }

    &.no-evts {
        pointer-events: none;
    }

    &--rail-cnt {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        z-index: 0;

        &--item-cnt {
            display: inline-block;
            position: absolute;
            white-space: nowrap;
            visibility: visible;

            // @include boost-performance;

            &--item-inner-cnt {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                white-space: normal;

                // &--figure-cnt {
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     width: 100%;
                //     height: 100%;

                //     background-size: cover;
                //     background-repeat: no-repeat;
                //     background-position: center center;

                //     z-index: 2;
                // }
            }
        }
    }
}
</style>
