<template>
    <div
    :class="[
        'application',
        theme,
        { 'app-loading': loading || !preloaded }
    ]">
        <!-- preloader -->
        <preloader
        v-if="!preloaded"
        :resources="resources"
        ref="preloader"
        @complete="preloadComplete" />

        <!-- navframe -->
        <navframe ref="navframe" />

        <!-- view -->
        <main>
            <router-view
            v-if="preloaded"
            v-slot="{ Component }">
                <component
                :is="Component"
                :key="$route.name"
                ref="page" />
            </router-view>
        </main>

        <cursor-comp
        v-if="completed && !is_touch_device"
        ref="cursor" />

        <!-- <div class="metà"></div> -->
        <div
        ref="window"
        class="window"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { fixVh } from '@/assets/libs/utils'
import { gsap } from 'gsap'
import splitting from 'splitting'

import Preloader from '@/components/Preloader.vue'
import Navframe from '@/components/Navframe.vue'
import Cursor from '@/components/Cursor.vue'

const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

export default {
    name: 'App',
    components: {
        navframe: Navframe,
        preloader: Preloader,
        'cursor-comp': Cursor
    },
    computed: {
        ...mapState({
            theme: state => state.theme,
            preloaded: state => state.preloaded,
            loading: state => state.loading,
            resources: state => state.resources
        })
    },
    data () {
        return {
            is_touch_device: isTouchDevice(),
            completed: false
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    methods: {
        start () {
            this.resize()
            this.logoEl = this.$refs.navframe.$refs.logo.$el
            this.logoBound = this.logoEl.getBoundingClientRect()

            gsap.set([
                this.$refs.navframe.$refs.navbar,
                this.$refs.navframe.$refs.schedule,
                this.$refs.navframe.$refs.contact
            ], { y: 40, autoAlpha: 0 })

            // console.log(window.innerWidth / 2, this.logoBound)
            gsap.set(this.logoEl, {
                y: (window.innerHeight / 2) - (this.logoBound.height / 2),
                x: (window.innerWidth / 2) - (this.logoBound.width / 2)
            })

            this.$refs.preloader.start()
            window.addEventListener('resize', this.resize)
        },
        preloadComplete () {
            this.$store.commit('SET_APP_PRELOADED', true)

            this.$nextTick(() => {
                let wall_split = splitting({
                    target: '[data-split]',
                    by: 'chars',
                    key: 'wall-',
                    whitespace: true
                })
                // console.log(wall_split)
                wall_split = wall_split[0]

                this.tl = gsap.timeline({
                    onComplete: () => {
                        this.completed = true
                    }
                })

                this.tl
                .add('start')
                .set(this.$refs.page.$refs.carousel.$refs.rail, { autoAlpha: 0, x: '150vw' })
                .add('play')
                .to(this.logoEl, {
                    y: 0,
                    x: 0,
                    width: '100px',
                    duration: 2,
                    ease: 'expo.inOut'
                })
                .to([
                    this.$refs.navframe.$refs.navbar,
                    this.$refs.navframe.$refs.schedule,
                    this.$refs.navframe.$refs.contact
                ], {
                    y: 0,
                    autoAlpha: 1,
                    duration: 1,
                    stagger: 0.3,
                    ease: 'expo.out'
                })
                .from(wall_split.chars, {
                    y: wall_split.words[0].clientHeight + 10,
                    autoAlpha: 0,
                    duration: 1.5,
                    stagger: 0.01,
                    ease: 'expo.out'
                })
                .add('end')
                .to(wall_split.chars, {
                    autoAlpha: 0.1,
                    duration: 0.5
                }, 'end')
                .to(this.$refs.page.$refs.carousel.$refs.rail, {
                    x: 0,
                    autoAlpha: 1,
                    duration: 2,
                    ease: 'expo.inOut'
                }, 'end-=0.6')
                .from(this.$refs.page.$refs.carousel_controls, {
                    autoAlpha: 0,
                    y: -20,
                    duration: 0.5
                })
            })
        },
        resize () {
            fixVh()
            this.is_touch_device = isTouchDevice()
        },
        //
        enterPage (el, next) {
            console.log('enterPage')

            gsap.set(el, {
                autoAlpha: 0
            })
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>
