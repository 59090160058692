<template>
    <router-link
    to="/"
    class="logo">
        <svg
        ref="svg"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="4961.000000pt"
        height="3508.000000pt"
        viewBox="0 0 4961.000000 3508.000000"
        preserveAspectRatio="xMidYMid meet">
            <g
            transform="translate(0.000000,3508.000000) scale(0.100000,-0.100000)"
            fill="currentColor"
            stroke="none">
                <path d="M28748 20929 l-338 -269 0 -3340 0 -3340 340 0 340 0 0 3610 c0 1986 -1 3610 -2 3610 -2 -1 -155 -122 -340 -271z" />
                <path d="M14950 19024 c0 -2 17 -19 38 -36 20 -18 86 -80 146 -138 501 -485 778 -1132 881 -2055 9 -82 18 -151 18 -152 1 -1 137 105 301 234 l298 236 -6 49 c-8 61 -65 249 -106 354 -259 651 -793 1217 -1362 1441 -108 43 -208 75 -208 67z" />
                <path d="M36220 18963 c606 -536 912 -1230 1012 -2297 3 -27 13 -20 298 204 162 128 299 236 303 240 11 10 -28 172 -74 306 -129 374 -337 708 -629 1007 -282 291 -584 484 -915 588 -33 10 -62 19 -65 19 -3 0 29 -30 70 -67z" />
                <path d="M13550 19013 c-45 -9 -223 -84 -340 -143 -590 -301 -1015 -814 -1250 -1510 -107 -315 -144 -545 -145 -890 0 -313 27 -510 109 -797 102 -354 274 -688 502 -973 240 -300 662 -630 950 -743 150 -59 223 -82 231 -73 4 4 0 12 -10 18 -73 40 -302 259 -437 418 -328 384 -560 1006 -642 1715 -20 173 -17 706 5 885 82 656 264 1137 614 1617 137 189 313 369 453 465 22 15 23 17 5 16 -11 0 -31 -3 -45 -5z" />
                <path d="M23845 18995 c-642 -220 -1164 -725 -1453 -1405 -318 -749 -314 -1526 13 -2296 154 -361 405 -703 700 -952 124 -104 355 -258 489 -327 105 -53 336 -140 356 -133 6 2 -25 32 -69 66 -90 72 -299 280 -392 392 -428 514 -685 1449 -639 2320 32 589 157 1069 389 1490 197 358 427 647 644 811 89 67 80 75 -38 34z" />
                <path d="M25530 18990 c279 -204 536 -537 751 -975 276 -564 385 -1338 293 -2085 -62 -509 -246 -1050 -478 -1410 -110 -170 -365 -447 -530 -575 -42 -33 -70 -61 -63 -63 19 -4 258 83 337 123 398 201 774 543 1010 918 121 194 215 393 289 615 257 769 197 1534 -178 2258 -246 474 -596 830 -1051 1067 -128 67 -319 144 -380 153 l-45 7 45 -33z" />
                <path d="M34705 18996 c-229 -78 -481 -217 -690 -381 -272 -213 -501 -496 -671 -830 -297 -581 -394 -1183 -289 -1789 138 -794 555 -1435 1215 -1869 63 -41 149 -93 190 -115 83 -45 324 -135 347 -130 9 2 -19 29 -68 67 -176 137 -428 416 -544 602 -342 550 -530 1369 -487 2116 18 313 50 520 122 798 64 249 117 392 222 597 202 398 443 711 691 899 89 67 80 75 -38 35z" />
                <path d="M17190 18940 c0 -10 422 -963 1305 -2945 257 -577 563 -1266 680 -1530 l212 -480 372 -3 c204 -1 371 0 371 4 0 3 -26 65 -59 137 -32 73 -197 443 -366 822 -169 380 -428 960 -575 1290 -147 330 -359 805 -470 1055 -112 250 -323 725 -470 1055 l-267 600 -367 3 c-246 1 -366 -1 -366 -8z" />
                <path d="M20856 18908 c61 -64 99 -117 132 -183 48 -97 57 -133 56 -240 -1 -112 -26 -205 -90 -324 -18 -36 -32 -66 -30 -68 3 -4 152 114 616 488 140 113 301 240 358 282 56 43 102 80 102 82 0 3 -266 5 -592 5 l-592 0 40 -42z" />
                <path d="M31476 18873 c116 -137 175 -297 158 -432 -9 -80 -40 -174 -85 -265 -22 -43 -38 -80 -36 -82 4 -4 63 42 502 396 182 147 380 304 440 350 61 46 117 89 125 97 12 11 -84 13 -578 13 l-593 0 67 -77z" />
                <path d="M30165 16528 c-121 -104 -230 -198 -243 -208 l-23 -19 682 -803 c376 -442 733 -861 794 -933 61 -71 198 -232 304 -357 l193 -228 370 0 369 0 -23 29 c-13 16 -338 422 -722 902 -1148 1434 -1453 1809 -1470 1808 -6 0 -110 -86 -231 -191z" />
                <path d="M13968 12327 c-32 -73 -132 -302 -223 -510 -91 -209 -165 -381 -165 -383 0 -3 28 -4 61 -2 l62 3 52 120 52 120 220 3 219 2 55 -122 54 -123 63 -3 c34 -2 62 -1 62 2 0 5 17 -34 -239 551 -111 253 -205 463 -209 467 -4 4 -33 -53 -64 -125z m146 -334 c42 -100 79 -188 82 -197 5 -14 -13 -16 -165 -16 -94 0 -171 3 -171 8 -1 17 166 400 171 394 3 -4 41 -89 83 -189z" />
                <path d="M25202 11948 l3 -513 57 -3 58 -3 2 369 3 370 160 -181 c88 -99 236 -266 329 -371 92 -105 174 -198 182 -206 12 -12 14 60 14 503 l0 517 -55 0 -55 0 -2 -372 -3 -372 -230 260 c-126 142 -232 261 -235 264 -3 3 -52 58 -109 123 -57 64 -107 120 -112 123 -5 3 -8 -206 -7 -508z" />
                <path d="M32276 12429 c-18 -43 -86 -198 -275 -630 -83 -191 -151 -352 -151 -358 0 -8 19 -11 57 -9 l58 3 54 123 55 122 220 -2 220 -3 51 -120 52 -120 63 -3 c56 -3 62 -1 58 15 -7 21 -438 1006 -444 1012 -2 2 -10 -11 -18 -30z m103 -440 c44 -105 81 -195 81 -200 0 -5 -76 -9 -171 -9 -159 0 -171 1 -165 18 23 62 162 382 167 382 3 0 43 -86 88 -191z"/>
                <path d="M36930 11947 l0 -517 55 0 55 0 2 371 3 371 180 -203 c99 -112 207 -233 240 -270 33 -36 105 -118 160 -182 55 -64 103 -116 108 -116 4 -1 6 230 5 512 l-3 512 -57 3 -58 3 -2 -371 -3 -371 -130 148 c-71 81 -148 168 -171 193 -22 25 -112 126 -199 225 -87 99 -164 187 -171 195 -12 12 -14 -66 -14 -503z"/>
                <path d="M18235 12422 c-101 -47 -145 -117 -145 -228 1 -130 47 -184 240 -276 135 -64 191 -100 211 -135 78 -135 -30 -277 -197 -260 -99 10 -175 71 -220 175 -1 2 -23 -7 -48 -20 -41 -20 -46 -26 -41 -47 3 -13 19 -43 36 -67 67 -98 163 -146 294 -147 98 -1 167 20 224 69 67 58 86 103 86 209 0 80 -3 95 -27 137 -35 59 -99 105 -235 170 -140 67 -178 92 -197 133 -77 161 146 275 278 143 l40 -40 49 24 49 24 -34 44 c-18 25 -56 58 -83 75 -44 27 -58 30 -144 33 -77 2 -104 -1 -136 -16z" />
                <path d="M27330 12433 c-144 -27 -300 -162 -361 -313 -27 -68 -37 -228 -20 -312 35 -168 171 -315 340 -369 87 -28 230 -30 319 -4 64 18 154 70 205 118 l27 26 -2 198 -3 198 -207 3 -208 2 0 -55 0 -55 150 0 150 0 0 -126 c0 -142 3 -134 -82 -179 -152 -80 -359 -46 -474 77 -183 196 -130 533 102 651 134 68 308 56 419 -28 l44 -34 35 44 36 44 -42 29 c-105 71 -176 92 -308 91 -52 -1 -106 -4 -120 -6z"/>
                <path d="M30595 12428 c-32 -11 -91 -52 -113 -78 -52 -61 -68 -180 -33 -256 29 -64 75 -99 233 -179 80 -41 160 -86 177 -100 76 -65 63 -211 -25 -266 -57 -35 -170 -34 -239 2 -44 22 -113 99 -127 141 -3 8 -78 -21 -96 -37 -11 -9 43 -100 80 -137 81 -81 196 -117 321 -99 160 23 246 118 247 274 0 83 -16 135 -53 175 -36 40 -133 101 -220 141 -153 69 -183 92 -197 156 -34 153 173 234 290 113 l38 -40 46 21 c25 11 46 24 46 29 0 22 -89 107 -135 128 -40 19 -66 24 -130 23 -44 0 -93 -5 -110 -11z"/>
                <path d="M35346 12429 c-172 -43 -329 -205 -365 -377 -57 -274 86 -533 338 -613 91 -29 240 -29 332 0 69 22 165 82 213 135 43 45 90 124 110 181 23 66 32 214 16 288 -30 149 -141 285 -286 354 -75 36 -83 37 -199 40 -66 1 -137 -2 -159 -8z m214 -99 c122 -23 232 -105 284 -212 53 -110 54 -261 2 -373 -90 -194 -339 -279 -540 -185 -197 93 -283 359 -180 561 78 156 259 242 434 209z"/>
                <path d="M11900 11930 l0 -500 55 0 55 0 2 238 3 237 270 0 270 0 3 -237 2 -238 55 0 55 0 0 500 0 500 -55 0 -55 0 -2 -207 -3 -208 -270 0 -270 0 -3 208 -2 207 -55 0 -55 0 0 -500z"/>
                <path d="M15395 12418 c-3 -7 -4 -231 -3 -498 l3 -485 58 -3 57 -3 0 501 0 500 -55 0 c-35 0 -57 -5 -60 -12z"/>
                <path d="M16505 12418 c-3 -7 -4 -231 -3 -498 l3 -485 58 -3 57 -3 0 196 0 195 113 0 112 -1 109 -194 110 -195 63 0 c35 0 63 3 63 7 0 3 -21 42 -47 87 -196 338 -186 320 -168 331 56 32 102 77 129 125 29 51 31 62 31 150 0 111 -16 152 -86 214 -80 73 -108 80 -336 84 -163 3 -204 1 -208 -10z m410 -117 c122 -55 150 -216 55 -311 -50 -50 -89 -60 -232 -60 l-118 0 0 195 0 195 128 0 c102 0 135 -4 167 -19z"/>
                <path d="M19480 12375 l0 -55 135 0 135 0 0 -445 0 -445 55 0 55 0 0 445 0 445 135 0 135 0 0 55 0 55 -325 0 -325 0 0 -55z"/>
                <path d="M20950 12421 c0 -5 45 -84 101 -177 56 -93 132 -222 170 -286 l69 -117 0 -206 0 -206 58 3 57 3 5 210 5 210 168 282 c92 155 167 285 167 288 0 3 -28 5 -61 3 l-62 -3 -136 -232 c-102 -174 -139 -229 -147 -220 -7 7 -71 112 -143 235 l-132 222 -59 0 c-33 0 -60 -4 -60 -9z"/>
                <path d="M22650 11930 l0 -500 260 0 260 0 0 55 0 55 -200 0 -200 0 -2 443 -3 442 -57 3 -58 3 0 -501z"/>
                <path d="M24090 11930 l0 -500 60 0 60 0 0 500 0 500 -60 0 -60 0 0 -500z"/>
                <path d="M33650 11930 l0 -500 260 0 260 0 0 55 0 55 -200 0 -200 0 -2 443 -3 442 -57 3 -58 3 0 -501z"/>
            </g>
        </svg>
    </router-link>
</template>

<script>
import { fixSvgSpace } from '@/assets/libs/utils'

export default {
    name: 'logo',
    mounted () {
        this.$nextTick(() => {
            fixSvgSpace(this.$refs.svg)
        })
    }
}
</script>

<style lang="scss" scoped>
a {
    display: inline-block;
    position: relative;
    width: 200px;
    text-decoration: none;
    text-transform: none;
    color: currentColor;
    // border: 1px solid red;

    > svg {
        width: 100%;
    }
}
</style>