export const ratio = {
    props: {
        ratio: {
            type: [String, Number, Boolean],
            required: false,
            default: false
        },
        'mobile-ratio': {
            type: [String, Boolean],
            required: false,
            default: false
        },
        breakpoint: {
            type: [String, Number],
            required: false,
            default: 960
        }
    },
    computed: {
        ratioHasDefaultSlot () {
            return 'default' in this.$slots
        },
        ratioClasses () {
            return {
                r: this.localRatio && this.localRatio.length > 0,
                'r-1-1': this.localRatio === '1/1' || this.localRatio === '1',
                'r-16-9': this.localRatio === '16/9',
                'r-2-1': this.localRatio === '2/1',
                'r-2-3': this.localRatio === '2/3',
                'r-21-4': this.localRatio === '21/4',
                'r-21-9': this.localRatio === '21/9',
                'r-3-4': this.localRatio === '3/4',
                'r-4-1': this.localRatio === '4/1',
                'r-4-3': this.localRatio === '4/3',
                'r-4-5': this.localRatio === '4/5' || this.localRatio === 'facebook',
                'r-9-16': this.localRatio === '9/16',
                'r-reel': this.localRatio === 'reel'
            }
        }
    },
    data () {
        return {
            localRatio: this.ratio
        }
    },
    watch: {
        ratio (newVal) {
            this.localRatio = newVal
        }
    }
}