<template>
    <div
    :class="['collapse', {
        'collapse-actived': on,
        'collapse-disabled': is_anim,
        'collapse-column': column
    }]"
    @mouseenter="handleHover"
    @mouseleave="handleHover">
        <div
        class="collapse--activator"
        @click.prevent.stop="handleClick">
            <div
            ref="activator">
                <slot name="activator" />
            </div>
        </div>

        <div
        ref="content"
        class="collapse--cnt">
            <slot name="items" />
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap'

// const isMobile = () => window.innerWidth < 1024
const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

export default {
    name: 'collapse',
    props: {
        column: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['change'],
    data () {
        return {
            on: false,
            is_anim: false,
            items_els: []
        }
    },
    mounted () {
        this.$nextTick(this.play)
    },
    methods: {
        play () {
            this.items_els = [...this.$el.querySelectorAll('.collapse-item')]

            gsap.set(this.items_els, {
                autoAlpha: 0,
                y: 20
            })
        },
        anim () {
            gsap.killTweensOf(this.$refs.content)
            gsap.killTweensOf(this.items_els)

            // gsap.fromTo(this.$refs.activator, {
            //     y: this.on ? this.activatorY : 0
            // }, {
            //     y: this.on ? 0 : this.activatorY,
            //     duration: 0.5,
            //     ease: 'expo.out'
            // })

            gsap.fromTo(this.$refs.content, {
                maxHeight: this.on ? 0 : this.$refs.content.scrollHeight
            }, {
                maxHeight: this.on ? this.$refs.content.scrollHeight : 0,
                duration: 1,
                ease: 'expo.out'
            })

            gsap.fromTo(this.items_els, {
                autoAlpha: this.on ? 0 : 1,
                y: this.on ? 20 : 0
            }, {
                autoAlpha: this.on ? 1 : 0,
                y: this.on ? 0 : 20,
                duration: 0.5,
                stagger: 0.03,
                ease: 'expo.out'
            })
        },
        handleClick () {
            if (!isTouchDevice()) return
            this.on = !this.on
            this.anim()
            this.$emit('change', this.on)
        },
        handleHover (evt) {
            if (isTouchDevice()) return
            this.on = evt.type === 'mouseenter'
            this.anim()
            this.$emit('change', this.on)
        },
        close () {
            if (!this.on) return
            this.on = false
            this.anim()
        },
        open () {
            if (this.on) return
            this.on = true
            this.anim()
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/mq';

.collapse {
    position: relative;
    display: block;
    pointer-events: auto;
    cursor: pointer;
    // border: 1px solid red;

    &.collapse-column {
        > .collapse--cnt {
            flex-flow: column nowrap;
            align-items: flex-start;

            > .collapse-item {
                margin-right: initial;
            }
        }
    }

    &.collapse-actived {
        > .collapse--cnt {
            margin-top: 0.25rem;
        }
    }

    &.collapse-disabled {
        pointer-events: none;
    }

    &--activator {
        // border: 1px solid blue;
    }

    &--cnt {
        display: block;
        position: relative;
        max-height: 0;
        overflow: hidden;
        // border: 1px solid green;

        > .collapse-item {
            margin: 0;
            padding: 0;
            margin-right: 0.3rem;
            &:last-child { margin-right: 0; }
        }
    }
}
</style>