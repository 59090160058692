import { createStore } from 'vuex'
import Http from '../assets/libs/Http'

const http = new Http()

const localTheme = localStorage.getItem('theme')
document.body.classList.add(localTheme || 'dark')

export default createStore({
    modules: {
    },
    state: {
        theme: localTheme || 'dark',
        preloaded: false,
        loading: true,
        page_enter: null,
        page_leave: null,
        in_transition: false,
        is_first_enter: true,
        resources: [
            { type: 'store_fetch', promise: 'GET_POSTS' }
        ],
        //
        me: {
            posts: [{}, {}, {}, {}, {}],
            motto: 'Qual è il tuo prossimo taglio?',
            name: 'Evoke',
            phone: '+39031976387',
            email: 'info@evoke-hairstyling.com/',
            address: {
                label: null,
                href: 'https://goo.gl/maps/mg29MXc1rxFALqSy5'
            },
            socials: {
                fb: 'https://www.facebook.com/evokehairstylingsalon',
                ig: 'https://www.instagram.com/evokehairstylingsalon',
                tw: null
            }
        },
        ig_items: [
            {
                media_url: 'https://images.pexels.com/photos/3366753/pexels-photo-3366753.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                caption: 'Lorem ipsum'
            },
            {
                media_url: 'https://images.pexels.com/photos/734478/pexels-photo-734478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                caption: 'Lorem ipsum'
            },
            {
                media_url: 'https://images.pexels.com/photos/7142792/pexels-photo-7142792.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                caption: 'Lorem ipsum'
            },
            {
                media_url: 'https://images.pexels.com/photos/5588918/pexels-photo-5588918.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                caption: 'Lorem ipsum'
            },
            {
                media_url: 'https://images.pexels.com/photos/5601010/pexels-photo-5601010.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
                caption: 'Lorem ipsum'
            },
            {
                media_url: 'https://images.pexels.com/photos/6144284/pexels-photo-6144284.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                caption: 'Lorem ipsum'
            },
            {
                media_url: 'https://images.pexels.com/photos/3065207/pexels-photo-3065207.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                caption: 'Lorem ipsum'
            },
            {
                media_url: 'https://images.pexels.com/photos/219550/pexels-photo-219550.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
                caption: 'Lorem ipsum'
            }
        ]
    },
    mutations: {
        SET_APP_LANG (state, str) {
            state.lang = str
        },
        SET_APP_PRELOADED (state, bool) {
            state.preloaded = bool
        },
        SET_APP_LOADING (state, bool) {
            state.loading = bool
        },
        SET_APP_IN_TRANSITION (state, bool) {
            state.in_transition = bool
        },
        SET_APP_PAGE_ENTER (state, str) {
            state.page_enter = str
        },
        SET_APP_PAGE_LEAVE (state, str) {
            state.page_leave = str
        },
        SET_APP_IS_FIRST_ENTER (state, bool) {
            state.is_first_enter = bool
        },
        SET_APP_THEME (state, str) {
            if (!str) {
                state.theme = state.theme === 'dark' ? 'light' : 'dark'
            } else {
                state.theme = str
            }

            document.body.classList = ''
            document.body.classList.add(state.theme)
            localStorage.setItem('theme', state.theme)
        },
        //
        SET_ME (state, obj) {
            state.me = obj
        },
        SET_IG_POSTS (state, arr) {
            state.ig_items = arr
        }
    },
    actions: {
        async GET_POSTS ({ commit }) {
            const { data } = await http.get(process.env.VUE_APP_API_IG + '/me/media', {
                access_token: 'IGQWRPRXJUcm5KYTNvcU11VDNDOFFlWmNxckozeTMtMy1pYUliT05WR3cyUzZAHU3dNUHh6eG9KcC1HTUZAYUzZAuMzNPVHI1LXlJeW92aVltdUpxVDdDQ1ozZAGV5MF9WVXhFN1RpeTBVZAm85NWZAUc0cyT1R0WHJ6S2cZD',
                limit: 50,
                fields: 'permalink,media_type,caption,thumbnail_url,media_url'
            })
            commit('SET_IG_POSTS', data.filter(i => i.media_type !== 'VIDEO'))
            return data
        },
        async GET_ME ({ commit }, query = {}) {
            const me = await http.get(process.env.VUE_APP_API + '/v1/me')
            commit('SET_ME', me)
            return me
        },
        async PRELOAD_IMAGES ({ commit }, urls) {
            if (urls.length > 1) {
                return urls.reduce((previousPromise, url) => {
                    return new Promise((resolve, reject) => {
                        const image = new Image()
                        image.src = url

                        if (image.complete) {
                            resolve(image)
                        } else {
                            image.onload = () => resolve(image)
                            image.onerror = resolve(image)
                        }
                    })
                }, Promise.resolve())
            }

            const promises = []

            urls.forEach(url => {
                const promise = new Promise((resolve, reject) => {
                    const image = new Image()
                    image.src = url

                    if (image.complete) {
                        resolve(image)
                    } else {
                        image.onload = () => resolve(image)
                        image.onerror = reject
                    }
                })

                promises.push(promise)
            })

            return Promise.all(promises)
        }
    },
    getters: {
    }
})
