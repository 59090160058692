<template>
    <div class="policy pa-ui">
        <p class="h3" style="padding-top: 10vh;">Policy</p>
    </div>
</template>

<script>
export default {
    name: 'policy'
}
</script>