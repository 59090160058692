import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// comps
import Logo from './components/Logo.vue'
import FigureComp from './components/FigureComp.vue'

const app = createApp(App)

app.component('logo', Logo)
app.component('figure-c', FigureComp)

app.use(store).use(router).mount('#app')
