<template>
    <div class="cursor-comp">
        <div
        ref="cnt"
        :class="['cursor-comp--cirlce', {
            actived: actived,
            hide: status === 'hide'
        }]"></div>

        <transition
        mode="out-in"
        @enter="enter"
        @leave="leave">
            <div
            :key="status"
            class="cursor-comp--text-cnt">
                <span>{{ text }}</span>
            </div>
        </transition>

        <transition
        mode="out-in"
        @enter="enterDrag"
        @leave="leaveDrag">
            <div
            v-if="status === 'drag'"
            :class="['cursor-comp--drag-cnt', status]">
                <div></div>
                <div></div>
            </div>
        </transition>
    </div>
</template>

<script>
import gsap from 'gsap'

import {
    lerp
} from '@/assets/libs/utils'

export default {
    name: 'cursor-comp',
    data () {
        return {
            status: null,
            text: null,
            actived: false,
            hide: false,
            els: [],

            mouse: { x: 0, y: 0 },
            interMouse: { x: 0, y: 0 },
            norm: { x: 0, y: 0 },

            magnetism: 5,
            attraction: 30,

            isLerp: true,
            lerp,
            isMobile: false,

            oldCtx: null,
            newCtx: null
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        $route () {
            this.removeListeners()
        }
    },
    methods: {
        start () {
            this.interMouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
            this.setListeners()
            this.play()
        },
        play () {
            window.addEventListener('mousemove', this.handlerMove)
            window.addEventListener('click', this.handlerClick)
            gsap.ticker.add(this.render)
        },
        // events
        handlerMove (evt) {
            this.mouse = {
                x: evt.clientX,
                y: evt.clientY
            }
        },
        handlerClick () {
            // console.log('click')
        },
        setListeners () {
            console.log('CURSOR SET LISTENERS')

            this.els = []
            this.els = [...document.querySelectorAll('[data-cursor]')]

            if (this.els.length > 0) {
                this.els.forEach((el) => {
                    const status = el.getAttribute('data-cursor')

                    el.addEventListener('mouseenter', () => this.handlerMouseenter(el, status))
                    el.addEventListener('mouseleave', () => this.handlerMouseleave(el))
                })
            }
        },
        removeListeners () {
            console.log('CURSOR SET REMOVE LISTENERS')

            if (this.els.length > 0) {
                this.els.forEach((el) => {
                    el.removeEventListener('mouseenter', this.handlerMouseenter)
                    el.removeEventListener('mouseleave', this.handlerMouseleave)
                })
            }

            this.status = null
            this.els = []
            this.actived = false
        },

        // renders

        render () {
            const lerpedX = lerp(this.mouse.x, this.interMouse.x, 0.75)
            const lerpedY = lerp(this.mouse.y, this.interMouse.y, 0.75)

            this.interMouse.x = this.isLerp ? lerpedX - (this.norm.x / this.magnetism) : this.mouse.x
            this.interMouse.y = this.isLerp ? lerpedY - (this.norm.y / this.magnetism) : this.mouse.y

            gsap.set(this.$el, {
                x: this.interMouse.x,
                y: this.interMouse.y
            })
        },

        // handlers
        handlerMouseenter (el, val) {
            this.actived = true

            if (val !== this.status) {
                this.status = val
                this.text = el.getAttribute('data-cursor-text') || null
            }
        },
        handlerMouseleave () {
            this.actived = false
            this.status = ''
            this.text = null
        },

        // trans
        enter (el, next) {
            gsap.fromTo(el, {
                autoAlpha: 0,
                y: el.clientHeight
            }, {
                autoAlpha: 1,
                y: 0,
                duration: 0.3,
                ease: 'expo.out',
                onComplete: next
            })
        },
        leave (el, next) {
            gsap.fromTo(el, {
                autoAlpha: 1,
                y: 0
            }, {
                autoAlpha: 0,
                y: -el.clientHeight,
                duration: 0.3,
                ease: 'expo.out',
                onComplete: next
            })
        },
        enterDrag (el, next) {
            gsap.fromTo(el, {
                autoAlpha: 0,
                scale: 0
            }, {
                autoAlpha: 1,
                scale: 1,
                delay: 0.25,
                duration: 0.5,
                ease: 'expo.out',
                onComplete: next
            })
        },
        leaveDrag (el, next) {
            gsap.to(el, {
                autoAlpha: 0,
                scale: 0,
                duration: 0.5,
                ease: 'expo.out',
                onComplete: next
            })
        },
        expand (status) {
            this.actived = true
            this.status = status || null
        },
        compress () {
            this.actived = false
            this.status = null
        }
    }
}
</script>

<style lang="scss" scoped>
$w: 88px;
$r: 100%;
$scale: 0.3;

.cursor-comp {
    position: absolute;
    top: calc(#{-$w} / 2);
    left: calc(#{-$w} / 2);
    width: $w;
    height: $w;

    border-radius: $r;

    display: flex;
    align-items: center;
    justify-content: center;

    transform-origin: center;
    z-index: var(--layer-cursor);
    pointer-events: none;

    &--cirlce {
        position: absolute;
        width: 100%;
        height: 100%;

        border-radius: $r;
        border: 3px solid var(--color-typo);

        transform-origin: 50% 50%;
        transform: scale($scale);
        transition: all 0.2s ease-out;

        z-index: -2;

        &.actived {
            transform: scale(1);
            background-color: var(--color-typo);
            opacity: 0.2;
        }

        &.hide {
            transform: scale(0) !important;
        }
    }

    &--text-cnt {
        white-space: nowrap;
        text-transform: uppercase;
        color: var(--color-typo);
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 2px;
    }

    &--drag-cnt {
        width: 50%;
        height: 50%;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        transform: scale(0);

        z-index: 1;

        > div {
            width: 6px;
            height: 6px;
            background-color: var(--color-typo);
            border-radius: 50%;
        }
    }
}
</style>