<template>
  <div class="navframe">
    <div class="navframe--cnt">
        <div>
            <logo
            ref="logo"
            data-cursor="explore"
            class="centered" />
        </div>

        <div ref="navbar">
            <a
            :href="$store.state.me.socials.fb"
            target="_blank"
            rel="noopener"
            class="mr-4 text-600 text-uppercase"
            data-cursor="explore">Fb</a>

            <a
            :href="$store.state.me.socials.ig"
            target="_blank"
            rel="noopener"
            class="text-uppercase text-600 mr-4"
            data-cursor="explore">Ig</a>

            <a
            type="button"
            class="text-300 text-uppercase"
            data-cursor="explore"
            @click="$store.commit('SET_APP_THEME')">{{ $store.state.theme === 'light' ? 'Dm' : 'Lm' }}</a>
        </div>
    </div>

    <div class="navframe--cnt navframe--cnt-bottom">
        <div ref="schedule">
            <collapse
            ref="orari_collapse"
            data-cursor="explore"
            column
            @change="$event ? $refs.contact_collapse.close() : () => {}">
                <template #activator>
                    <a rel="button">
                        <small class="text-uppercase">
                            <!-- <span class="mr-2">Siamo</span> -->
                            <span class="text-600">Orari</span>
                        </small>
                    </a>
                </template>

                lunedì	Chiuso
                martedì	09–19
                mercoledì	14–20:30
                giovedì	09–19
                venerdì	09–19
                sabato	09–18
                domenica    Chiuso

                <template #items>
                    <p class="collapse-item text-mute mb-0">
                        <small class="between">
                            <span>Mar</span>
                            <span>09 - 19</span>
                        </small>
                        <small class="between">
                            <span>Mer</span>
                            <span>14 - 20:30</span>
                        </small>
                        <small class="between">
                            <span>Gio - Sab</span>
                            <!-- <span class="mx-2"></span> -->
                            <span>09 - 19</span>
                        </small></p>
                    <p class="collapse-item text-mute mb-0">
                        <small class="between">
                            <span>Lun - Dom </span>
                            <!-- <span class="mx-2"></span> -->
                            <span>Chiuso</span>
                        </small>
                    </p>
                </template>
            </collapse>
        </div>

        <div ref="contact">
            <collapse
            ref="contact_collapse"
            data-cursor="explore"
            @change="$event ? $refs.orari_collapse.close() : () => {}">
                <template #activator>
                    <a
                    rel="button"
                    class="text-uppercase text-600">
                        <small>Contatti</small>
                    </a>
                </template>

                <template #items>
                    <a
                    :href="$store.state.me.address.href"
                    target="_blank"
                    rel="noopener"
                    class="collapse-item text-mute"><small>Maps</small></a>
                    <span class="collapse-item text-mute">-</span>
                    <a
                    :href="`tel:${$store.state.me.phone}`"
                    class="collapse-item text-mute"><small>Telefono</small></a>
                    <span class="collapse-item text-mute">-</span>
                    <router-link
                    to="/policy"
                    class="collapse-item text-mute">
                        <small>Policy</small>
                    </router-link>
                </template>
            </collapse>
        </div>
    </div>
  </div>
</template>

<script>
import Collapse from './Collapse.vue'

export default {
    name: 'navframe',
    components: {
        collapse: Collapse
    }
}
</script>

<style lang="scss" scoped>
.navframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    z-index: var(--layer-navframe);

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    pointer-events: none;

    &--cnt {
        position: relative;
        width: 100%;
        max-width: var(--boxed-ui);
        margin: 0 auto;
        padding: var(--size-ui);

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        pointer-events: auto;

        > div {
            position: relative;

            // &:nth-child(2) {
            //     text-align: center;
            // }

            &:last-child {
                text-align: right;
            }
        }
    }

    &--cnt-bottom {
        // border: 1px solid red;

        > div {
            &:first-child {
                position: absolute;
                width: 50%;
                max-width: 180px;
                left: var(--size-ui);
                bottom: var(--size-ui);
            }

            &:last-child {
                position: absolute;
                // width: 50%;
                right: var(--size-ui);
                bottom: var(--size-ui);
            }
        }
    }
}
</style>